/* eslint-disable react/no-unescaped-entities */
const YourRights = () => {
  return (
    <>
      <div className="block1">
        <p className="text-left text-sm">
          O fato de nos confiar os seus dados pessoais não significa que você
          perde o controle dos mesmos, pelo contrário! Uma pequena explicação...
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Você encontrará abaixo uma breve descrição desses direitos, bem como
          as modalidades da sua implementação.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Para saber exatamente os direitos relacionados com os tratamentos que
          lhe dizem respeito, consulte o título "Os seus direitos" presente na
          descrição do tratamento em questão.
        </p>

        <h3 className="text-sm font-bold uppercase text-center my-3.5">
          O direito de acesso:
        </h3>

        <p className="text-left text-sm mx-0 my-3.5">
          Este direito permite-nos fazer perguntas sobre a natureza do seu
          tratamento (tipo de dados, origem da coleta de dados, etc.). Também
          permite que você solicite uma cópia de todas as suas informações. Este
          direito aplica-se independentemente da base legal que lhe diz
          respeito.
        </p>

        <h3 className="text-sm font-bold uppercase text-center my-3.5">
          O direito de retificação:
        </h3>

        <p className="text-left text-sm mx-0 my-3.5">
          Mudança de casa, casamento, novo esporte ... há muitas situações que
          podem levar a atualizar seus dados. O direito de retificação é
          precisamente isso. Este direito aplica-se independentemente da base
          legal que lhe diz respeito.
        </p>

        <h3 className="text-sm font-bold uppercase text-center my-3.5">
          Direito ao esquecimento:
        </h3>

        <p className="text-left text-sm mx-0 my-3.5">
          É o direito de ser esquecido ... e isso é normal. A Decathlon não tem
          motivos para manter os seus dados além do necessário. Todos os seus
          dados são automaticamente excluídos após a expiração do período de
          retenção anunciado no momento da coleta dos seus dados.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Quando os seus dados são processados com base no seu consentimento ou
          no interesse legítimo (não obrigatório) da Decathlon, você pode obter
          a exclusão antecipada dos seus dados. Como? Tudo o que precisa fazer é
          demonstrar a sua vontade de retirar o seu consentimento ou afirmar o
          seu direito de se opor ao tratamento.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Direito à portabilidade:
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Este direito permite que você solicite a obtenção dos seus dados,
          desde que sejam sujeitos a um tratamento com base no seu consentimento
          ou numa relação contratual. Qual a diferença entre o direito à
          portabilidade e o direito de acesso? Somos obrigados a enviar os dados
          num formato legível/acessível por você ou por outra entidade que não
          seja a Decathlon.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Pode exercer os seus direitos entrando em contato connosco através dos
          seguintes endereços:
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          - por e-mail : dados.pessoais@decathlon.com <br />- por correio:
          IGUASPORT LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede
          na Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Para que o seu pedido seja gerido da forma mais eficiente possível,
          não se esqueça: <br />
          - de indicar o(s) tratamento(s) em questão <br />
          - de indicar o(s) direito(s) que deseja implementar <br />- de
          fornecer uma cópia do seu documento de identidade (estes dados são
          usados para verificar se o solicitante é a pessoa a que corresponde ao
          tratamento).
        </p>

        <h3 className="text-blue text-sm font-bold uppercase text-center my-3.5 w-full">
          DPO
        </h3>

        <p className="text-left text-sm mx-0 my-3.5">
          DPO A forma como processamos os seus dados é importante para nós, a
          DECATHLON confiou em mim designando-me como delegado de proteção de
          dados.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          A DECATHLON confiou-me a missão de apoiar a nossa atividade comercial
          sem comprometer o respeito pelos seus direitos e pela sua vida
          privada.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Caso você tenha alguma preocupação, queira exercer um direito ou tenha
          uma pergunta, estou à sua disposição.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          dados.pessoais@decathlon.com
        </p>
      </div>
    </>
  )
}

export default YourRights
