const SportistClient = () => {
  return (
    <>
      {/* BLOCK 1 */}
      <table className=" w-full">
        <tr className="block1 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Responsável pelo tratamento
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>
          {/* BLOCK 2 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Dados tratados
            </h3>
            <p className="text-center text-sm my-3.5">
              Tratamos dos dados necessários ao tratamento do seu contato:
              sobrenome, nome, e-mail, CPF, telefone, endereço, descrição do
              produto, a natureza da anomalia, e quando for necessário e
              justificado pela natureza do problema, dados relativos ao seu
              estado de saúde.
            </p>
          </td>
        </tr>

        {/* BLOCK 3 */}
        <tr className="block3 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Prazo de conservação
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados são eliminados passados 3 anos após o seu último
              contato..
            </p>
          </td>
          {/* BLOCK 4 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Os seus direitos
            </h3>
            <p className="text-center text-sm my-3.5">
              Pode consultar e retificar os seus dados pessoais, enviando um
              e-mail para: dados.pessoais@decathlon.com Para mais informações
              sobre os seus dados
            </p>
          </td>
        </tr>

        {/* BLOCK 5 */}
        <tr className="block5 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Destinatários dos dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados destinam-se ao nosso serviço de atendimento com o
              cliente, às nossas equipas de loja, ao nosso dept. qualidade,
              comercial e dept. jurídico.
            </p>
          </td>
          {/* BLOCK 6 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Transferência fora do Brasil
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais permanecem no Brasil e poderão ser enviados
              para a União Europeia e nos Estados Unidos, onde nos empenhamos em
              protegê-los de acordo com o regulamento nacional brasileiro e
              europeu.
            </p>
          </td>
        </tr>

        {/* BLOCK 7 */}
        <tr className="block7 xl:w-3/6 sm:w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Fundamento legal para o tratamento de dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais são tratados após recebermos o seu
              consentimento.
            </p>
          </td>
          {/* BLOCK 8 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Definição de perfis e tomada de decisão inteiramente automatizada
              que produz efeitos jurídicos ou que o afeta de forma significativa
            </h3>
            <p className="text-center text-sm my-3.5">
              Este tratamento não está sujeito a uma ação ou tomada de decisão
              inteiramente automatizada da nossa parte.
            </p>
          </td>
        </tr>
      </table>
    </>
  )
}

export default SportistClient
