const Fraud = () => {
  return (
    <>
      <table className=" w-full">
        {/* BLOCK 1 */}
        <tr className="block1 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Responsável pelo tratamento
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>
          {/* BLOCK 2 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Dados tratados
            </h3>
            <p className="text-center text-sm my-3.5">
              Tratamos os dados que permitem precaver-nos dos meios de pagamento
              fraudulentos online: o seu e-mail, ID do cliente, CPF, número de
              telefone, endereço de faturamento, endereço de entrega,
              informações ligadas ao modo de pagamento, valor da encomenda,
              frequência de encomendas.
            </p>
          </td>
        </tr>

        {/* BLOCK 3 */}
        <tr className="block3 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Prazo de conservação
            </h3>
            <p className="text-center text-sm my-3.5">
              No âmbito da mento fraudulentos online guardamos os seus prevenção
              contra os meios de paga dados pessoais durante 12 meses.
            </p>
          </td>
          {/* BLOCK 4 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Os seus direitos
            </h3>
            <p className="text-center text-sm my-3.5">
              Pode consultar e retificar os seus dados pessoais, enviando um
              e-mail para: [dados.pessoais@decathlon.com]
            </p>
          </td>
        </tr>
        {/* BLOCK 5 */}
        <tr className="block5 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Destinatários dos dados
            </h3>
            <p className="text-center text-sm my-3.5">
              No âmbito da prevenção contra os meios de pagamento fraudulentos
              online, alguns dos nossos serviços podem acessar aos seus dados:
              direção administrativa, financeira e informática.
            </p>
          </td>
          {/* BLOCK 6 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Transferência fora do Brasil
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>
        </tr>
        {/* BLOCK 7 */}
        <tr className="block7 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Fundamento legal para o tratamento de dados
            </h3>
            <p className="text-center text-sm my-3.5">
              A verificação prévia da sua encomenda baseia-se no interesse
              legítimo do responsável pelo tratamento (DECATHLON).
            </p>
          </td>
          {/* BLOCK 8 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Definição de perfis ou tomada de decisão inteiramente automatizada
              que produz efeitos jurídicos ou que o afeta de forma significativa
            </h3>
            <p className="text-center text-sm my-3.5">
              Quando realiza uma encomenda, podemos definir um perfil ou tomar
              uma decisão de forma automatizada. Esta verificação é necessária
              para finalizar a encomenda.
            </p>
          </td>
        </tr>
      </table>
    </>
  )
}

export default Fraud
