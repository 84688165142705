const Reviews = () => {
  return (
    <>
      <table className=" w-full">
        {/* BLOCK 1 */}
        <tr className="block1 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Responsável pelo tratamento
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>
          {/* BLOCK 2 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Dados tratados
            </h3>
            <p className="text-center text-sm my-3.5">
              Tratamos os dados que nos permitem gerenciar a publicação de suas
              avaliações, mas também aquelas necessárias para contatá-lo, se
              necessário: seu nome, seu primeiro nome e seu email.
            </p>
          </td>
        </tr>

        {/* BLOCK 3 */}
        <tr className="block3 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Prazo de conservação
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados serão eliminados 3 anos após o seu último contato.
            </p>
          </td>
          {/* BLOCK 4 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Os seus direitos
            </h3>
            <p className="text-center text-sm my-3.5">
              Você poderá consultar e retificar os seus dados pessoais, enviando
              um e-mail para: [dados.pessoais@decathlon.com] Sob determinadas
              condições, poderá solicitar a transferência dos seus dados para
              uma outra entidade que não a DECATHLON.
            </p>
          </td>
        </tr>

        {/* BLOCK 5 */}
        <tr className="block5 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Destinatários dos dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados são destinados às nossas equipes de marketing e
              comunicação e informática. Se nossos subcontratados processarem
              seus dados, eles o farão apenas para fins de hospedagem de dados,
              mas também para permitir que informamos a você no caso de
              publicação de suas avaliações, finalmente, para fins de análise. e
              apenas nas instruções da DECATHLON.
            </p>
          </td>
          {/* BLOCK 6 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Transferência fora do Brasil
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais permanecem no Brasil e poderão ser enviados
              para a União Europeia e nos Estados Unidos, onde nos empenhamos em
              protegê-los de acordo com o regulamento nacional brasileiro e
              europeu.
            </p>
          </td>
        </tr>

        {/* BLOCK 7 */}
        <tr className="block7 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Fundamento legal para o tratamento de dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais só serão objeto de tratamento após obtermos
              o seu consentimento.
            </p>
          </td>
          {/* BLOCK 8 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Definição de perfis ou tomada de decisão inteiramente automatizada
              que produz efeitos jurídicos ou que o afeta de forma significativa
            </h3>
            <p className="text-center text-sm my-3.5">
              A sua encomenda não está sujeita a uma ação ou tomada de decisão
              inteiramente automatizada da nossa parte.
            </p>
          </td>
        </tr>
      </table>
    </>
  )
}

export default Reviews
