const AccountManagement = () => {
  return (
    <>
      <div className="block1">
        <p className="text-left text-sm my-3.5">
          Por quê criar uma conta? A sua conta DECATHLON permite que você
          centralize as informações úteis e importantes.
        </p>
        <p className="text-left text-sm my-3.5">
          De fato, a conta DECATHLON lhe dá a possibilidade de acompanhar o
          estado da seu pedido, consultar o seu histórico de compras, dentre
          outras facilidades! Também poderá indicar se você pretende receber as
          nossas comunicações (os nossos conselhos sobre esportes, dicas, etc…).
        </p>
        <p className="text-left text-sm my-3.5">
          Também tem a possibilidade de voltar atrás quando quiser cancelar a
          sua conta.
        </p>
      </div>

      <h3 className="text-gray text-sm font-bold uppercase text-left my-3.5 w-full">
        GESTÃO DA SUA CONTA DECATHLON
      </h3>
      <div className="block2 pb-10">
        <p className="text-left text-sm my-3.5">
          A conta Decathlon permite que você desfrute de muitos benefícios
          atribuídos na base principal da sua relação com a Decathlon, nas suas
          compras online ou na loja.
        </p>
        <p className="text-left text-sm my-3.5">
          A administração da conta requer o processamento de certas informações
          sobre você (histórico de compras em particular).
        </p>
        <p className="text-left text-sm my-3.5">
          Uma vez que criou uma conta, as suas compras serão automaticamente
          consideradas quando a compra for feita online.
        </p>
        <p className="text-left text-sm my-3.5">
          Uma vez que criou uma conta, as suas compras serão automaticamente
          consideradas quando a compra for feita online.
        </p>
        Quando a compra é feita na loja, esta associação é feita apenas com a
        apresentação do CPF no caixa.
      </div>

      <table className=" w-full">
        <tr className="block1 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Responsável pelo tratamento
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>
          {/* BLOCK 2 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Dados tratados
            </h3>
            <p className="text-center text-sm my-3.5">
              Tratamos os dados que nos permitem gerenciar o programa de
              fidelidade: nome, sobrenome, e-mail, CPF, número de telefone,
              endereço, histórico de compras, loja de preferência, esporte(s),
              eventos.. Os dados de e-mail ou telefone que você nos fornece
              podem ser usados para lhe enviarmos informações comerciais de seu
              interesse sobre os nossos produtos e/ou serviços, desde que nos
              autorize expressamente.
            </p>
          </td>
        </tr>

        {/* BLOCK 3 */}
        <tr className="block3 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Destinatários dos dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados são destinados às nossas equipes de marketing e
              comunicação, centro de relação com o cliente, equipes de loja e
              informática.
            </p>
          </td>
          {/* BLOCK 4 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Prazo de conservação
            </h3>
            <p className="text-center text-sm my-3.5">
              Guardamos os seus dados até ao encerramento da conta ou 2 anos
              após a última conexão.
            </p>
          </td>
        </tr>

        {/* BLOCK 5 */}
        <tr className="block5 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Os seus direitos
            </h3>
            <p className="text-center text-sm my-3.5">
              Você pode pedir para consultar, retificar ou eliminar os seus
              dados pessoais.Também tem o direito de se opor à definição de
              perfil. Basta enviar um e-mail para: dados.pessoais@decathlon.com
            </p>
          </td>
          {/* BLOCK 6 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Transferência fora do Brasil
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais permanecem no Brasil e poderão ser enviados
              para a União Europeia e Estados Unidos, onde nos empenhamos em
              protegê-los de acordo com o regulamento nacional brasileiro e
              europeu.
            </p>
          </td>
        </tr>
        {/* BLOCK 7 */}
        <tr className="block7 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Fundamento legal para o tratamento de dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais só serão objeto de tratamento após obtermos
              o seu consentimento.
            </p>
          </td>
          {/* BLOCK 8 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Definição de perfis e tomada de decisão inteiramente automatizada
              que produz efeitos jurídicos ou que o afeta de forma significativa
            </h3>
            <p className="text-center text-sm my-3.5">
              No âmbito da gestão de sua conta Decathlon, podemos efetuar uma
              definição de perfis ou tomada de decisão de forma inteiramente
              automatizada. Esta operação será efetuada apenas após o seu
              consentimento.
            </p>
          </td>
        </tr>
      </table>
    </>
  )
}

export default AccountManagement
