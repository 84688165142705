import { Link } from 'gatsby'

const Order = () => {
  return (
    <>
      {/* BLOCK 1 */}
      <table className=" w-full">
        <tr className="block1 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Responsável pelo tratamento
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>

          {/* BLOCK 2 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Dados tratados
            </h3>
            <p className="text-center text-sm my-3.5">
              Tratamos os dados que nos permitem efetuar o pagamento e a entrega
              dos seus artigos (nome, sobrenome, e-mail, cpf, número de
              telefone, endereço de entrega e n.º de pedido). O seu e-mail e o
              seu número de telefone permitem-nos notificá-lo sobre o estado da
              sua encomenda.
            </p>
          </td>
        </tr>
        {/* BLOCK 3 */}
        <tr className="block3 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Prazo de conservação
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados enviados para os nossos subcontratados que fazem o
              serviço de entrega são guardados durante 1 ano após a entrega. O
              conjunto de todos os outros dados ligados à sua encomenda serão
              guardados durante 10 anos.
            </p>
            <p className="text-center text-sm my-3.5">
              Os dados ligados ao seu cartão bancário não são guardados.
            </p>
            <p className="text-center text-sm my-3.5">
              Os dados fiscais são guardados durante 5 anos para pessoas físicas
              e jurídicas mais o ano corrente.
            </p>
          </td>
          {/* BLOCK 4 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Os seus direitos
            </h3>
            <p className="text-center text-sm my-3.5">
              Você poderá consultar e retificar os seus dados pessoais, enviando
              um e-mail para: [dados.pessoais@decathlon.com] Sob determinadas
              condições, poderá solicitar a transferência dos seus dados para
              uma outra entidade que não a DECATHLON.
            </p>
          </td>
        </tr>

        {/* BLOCK 5 */}
        <tr className="w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Destinatários dos dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Para tratar da sua encomenda ou para responder às suas questões,
              alguns dos nossos serviços podem ter acesso aos seus dados: a
              informática, o centro de relação com o cliente, as nossas equipes
              de loja, as nossas equipes de logística. Utilizamos também
              subcontratados para fins técnicos como o armazenamento e entregas.
              O seu acesso é estritamente definido pela DECATHLON. Não existe a
              possibilidade de utilizar os seus dados para outros fins que não
              seja a execução da prestação de serviço relacionada.
            </p>
          </td>
          {/* BLOCK 6 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Transferência fora do Brasil
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais permanecem no Brasil e poderão ser enviados
              para a União Europeia e nos Estados Unidos, onde nos empenhamos em
              protegê-los de acordo com o regulamento nacional brasileiro e
              europeu.{' '}
              <Link className="underline" to="/">
                Para mais informações sobre as transferência de dados
              </Link>
            </p>
          </td>
        </tr>

        {/* BLOCK 7 */}
        <tr className="block7 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Fundamento legal para o tratamento de dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Quando você efetua uma encomenda na DECATHLON em termos
              “jurídicos” trata-se de um contrato. Para cumprirmos com a nossa
              parte do contrato precisamos tratar os seus dados pessoais.
            </p>
          </td>
          {/* BLOCK 8 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Definição de perfis ou tomada de decisão inteiramente automatizada
              que produz efeitos jurídicos ou que o afeta de forma significativa
            </h3>
            <p className="text-center text-sm my-3.5">
              A sua encomenda não está sujeita a uma ação ou tomada de decisão
              inteiramente automatizada da nossa parte.
            </p>
          </td>
        </tr>
      </table>
    </>
  )
}

export default Order
