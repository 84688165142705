/* eslint-disable react/no-unescaped-entities */
const SalesWithdrawals = () => {
  return (
    <>
      <div className="block1">
        <p className="text-left text-sm">
          Na DECATHLON, a sua segurança é a nossa prioridade. Desenvolvemos os
          nossos produtos no maior respeito pelas normas e regulamentações em
          vigor. No entanto, sabemos que não existe risco nulo.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Caso exista algum problema em um dos nossos produtos, o nosso objetivo
          é ser o mais reativo possível. Como tal, podemos decidir utilizar o
          seu número de telefone ou e-mail se os seus dados estiverem na nossa
          posse.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Isso significa que você poderá ser contatado no momento de uma
          eventual retirada de venda (recall), ou seja, podemos utilizar o seu
          e-mail ou número de telefone se os dados se encontrarem no nosso
          programa de fidelidade.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          E se não tivermos esses dados? Pode haver necessidade de solicitá-los
          na compra de produtos suscetíveis de apresentar um risco para a sua
          segurança em caso de anomalia (bicicleta, material de mergulho,
          material de escalada, etc…). Comprometemo-nos que esses dados não são
          utilizados para outras finalidades.
        </p>

        <h3 className="text-sm font-bold uppercase text-left my-3.5">
          TRATAMENTO NECESSÁRIO À GESTÃO DAS RETIRADAS DE VENDA
        </h3>
      </div>

      <table className=" w-full">
        {/* BLOCK 1 */}
        <tr className="block1 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Responsável pelo tratamento
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>
          {/* BLOCK 2 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Dados tratados
            </h3>
            <p className="text-center text-sm my-3.5">
              Tratamos dos dados necessários às retiradas de vendas dos nossos
              produtos: sobrenome, nome, e-mail, descrição dos produtos
              comprados.
            </p>
          </td>
        </tr>

        {/* BLOCK 3 */}
        <tr className="block3 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Prazo de conservação
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados serão eliminados 10 anos após a compra do produto.
            </p>
          </td>
          {/* BLOCK 4 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Os seus direitos
            </h3>
            <p className="text-center text-sm my-3.5">
              Pode consultar e retificar os seus dados pessoais, enviando um
              e-mail para: dados.pessoais@decathlon.com Para mais informações
              sobre os seus dados
            </p>
          </td>
        </tr>

        {/* BLOCK 5 */}
        <tr className="block5 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Destinatários dos dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados destinam-se ao nosso departamento de qualidade,
              departamento comercial, informática e departamento jurídico e o
              serviço de atendimento com o cliente.
            </p>
          </td>
          {/* BLOCK 6 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Transferência fora do Brasil
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais permanecem no Brasil e poderão ser enviados
              para a União Europeia e nos Estados Unidos, onde nos empenhamos em
              protegê-los de acordo com o regulamento nacional brasileiro e
              europeu.
            </p>
          </td>
        </tr>

        {/* BLOCK 7 */}
        <tr className="block7 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Fundamento legal para o tratamento de dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Podemos utilizar os seus dados para contatá-lo no âmbito de um
              aviso segurança relacionado com um produto. Em termos "jurídicos",
              trata-se de um interesse legítimo do responsável pelo tratamento
              (DECATHLON).
            </p>
          </td>
          {/* BLOCK 8 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Definição de perfis e tomada de decisão inteiramente automatizada
              que produz efeitos jurídicos ou que o afeta de forma significativa
            </h3>
            <p className="text-center text-sm my-3.5">
              Este tratamento não está sujeito a uma ação ou tomada de decisão
              inteiramente automatizada da nossa parte.
            </p>
          </td>
        </tr>
      </table>

      <h3 className="text-sm font-bold uppercase text-left my-3.5">
        TRATAMENTO NECESSÁRIO À GESTÃO DAS RETIRADAS DE VENDA
      </h3>

      <p className="text-left text-sm">
        Como apaixonados, a satisfação dos nossos esportistas é o que nos move.
        Mas por vezes acontecem imprevistos: um erro na sua encomenda, um atraso
        na entrega, uma anomalia com o produto...
      </p>

      <p className="text-left text-sm mx-0 my-3.5">
        Para lhe garantirmos o melhor acompanhamento nestas situações e para lhe
        proporcionar a satisfação esperada, o nosso serviço de atendimento ao
        cliente está ao seu dispor.
      </p>

      <p className="text-left text-sm mx-0 my-3.5">
        Para respondermos às suas chamadas e mensagens, teremos certamente de
        tratar alguns dos seus dados pessoais. Desta forma, estes dados poderão
        ser partilhados com outros serviços internos, quando estes forem
        diretamente implicados na resolução do seu problema.
      </p>
    </>
  )
}

export default SalesWithdrawals
