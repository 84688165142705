const Marketing = () => {
  return (
    <>
      <div className="block1">
        <p className="text-left text-sm my-3.5">
          Em termos de comunicação de marketing, o respeito pela sua
          tranquilidade e a relevância das nossas mensagens são dois princípios
          com os quais somos completamente exigentes.
        </p>
        <p className="text-left text-sm my-3.5">
          Não pretendemos lhe enviar publicidade por e-mail ou correio se você
          não desejar. Se os nossos conselhos forem do seu interesse, não
          pretendemos falar sobre luvas de boxe se a sua paixão é o ballet!
        </p>
        <p className="text-left text-sm my-3.5">
          Ao dar-nos a honra de o conhecer melhor (esportes praticados, nível de
          prática, histórico de compras, etc…) e de nos autorizar a contatá-lo,
          poderemos lhe enviar mensagens personalizadas: conselhos adaptados à
          sua prática esportiva, dicas, eventos que são importantes para você...
          Para concluir, relembramos o essencial: comprometemo-nos em nunca
          ceder nenhum dos seus dados. Ou seja, não irá receber publicidade para
          detergente. E este ponto é importante para nós!
        </p>
      </div>

      <table className=" w-full">
        <tr className="block1 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Responsável pelo tratamento
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>
          {/* BLOCK 2 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Dados tratados
            </h3>
            <p className="text-center text-sm my-3.5">
              Tratamos os dados que nos permitem gerir o marketing direto:
              sobrenome, nome, e-mail, CPF, número de telefone, endereço,
              histórico de compras, esporte praticado, histórico de navegação,
              loja de preferência, eventos.
            </p>
          </td>
        </tr>
        {/* BLOCK 3 */}
        <tr className="block3 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Prazo de conservação
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados serão eliminados 3 anos após o seu último contato.
            </p>
          </td>
          {/* BLOCK 4 */}
          <td className="border-solid border-[1px] border-gray p-[10px]</td>">
            <h3 className="text-sm font-bold text-center text-gray">
              Os seus direitos
            </h3>
            <p className="text-center text-sm my-3.5">
              Você pode pedir para consultar, retificar ou eliminar os seus
              dados pessoais. Sob certas condições, também pode solicitar que
              seus dados sejam transferidos para outra entidade que não a
              DECATHLON. Também tem o direito de se opor à definição de perfil.
              Basta enviar um e-mail para: dados.pessoais@decathlon.com
            </p>
          </td>
        </tr>
        {/* BLOCK 5 */}
        <tr className="block5 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Destinatários dos dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados são destinados às nossas equipes de marketing e
              comunicação e informática.
            </p>
          </td>
          {/* BLOCK 6 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Transferência fora do Brasil
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais permanecem no Brasil e poderão ser enviados
              para a União Europeia e nos Estados Unidos, onde nos empenhamos em
              protegê-los de acordo com o regulamento nacional brasileiro e
              europeu.
            </p>
          </td>
        </tr>

        {/* BLOCK 7 */}
        <tr className="block7 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Fundamento legal para o tratamento de dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais só serão objeto de tratamento após obtermos
              o seu consentimento.
            </p>
          </td>
          {/* BLOCK 8 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Definição de perfis e tomada de decisão inteiramente automatizada
              que produz efeitos jurídicos ou que o afeta de forma significativa
            </h3>
            <p className="text-center text-sm my-3.5">
              No âmbito da gestão do marketing direto, podemos efetuar uma
              definição de perfis ou tomada de decisão de forma inteiramente
              automatizada. Esta operação será efetuada apenas após o seu
              consentimento.
            </p>
          </td>
        </tr>
      </table>
    </>
  )
}

export default Marketing
