import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import AccountManagement from './AccountManagement'
import Billing from './Billing'
import Fraud from './Fraud'
import Marketing from './Marketing'
import PresentCard from './PresentCard'
import Cookies from './Cookies'
import Cart from './Cart'
import SalesWithdrawals from './SalesWithdrawals'
import SportistClient from './SportistClient'
import DecathlonPlay from './DecathlonPlay'
import ImSportist from './ImSportist'
import Wifi from './Wifi'
import Reviews from './Reviews'
import GoodToKnow from './GoodToKnow'
import YourRights from './YourRights'
import Order from './Order'

const PrivacyPolicy = () => {
  return (
    <>
      <section className="section-services privacy-policy_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar CupomDesconto-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- CupomDesconto Products-> */}
              <div className="services-content">
                <div className="section__top align-center py-0 px-2.5">
                  <h1 className="text-blue italic text-2xl font-semibold uppercase text-center mb-12">
                    Política de privacidade
                  </h1>
                  <h3 className="text-blue text-sm font-semibold text-left">
                    OS NOSSOS COMPROMISSOS
                  </h3>
                  <p className="text-left text-sm my-3.5">
                    Os seus dados não são cedidos nem partilhados.
                  </p>

                  <p className="text-left text-sm my-3.5">
                    Os seus dados esportivos (ou seja, todos os dados que você
                    compartilha conosco...) são apenas para a DECATHLON. Para
                    nós é importante garantir que não compartilhamos os seus
                    dados pessoais. Estamos comprometidos em nunca vender ou
                    locar esses dados, isso é importante para nós. Se os
                    confiarmos a subcontratados, eles agem somente em nosso
                    próprio nome e de acordo com nossas instruções.
                  </p>

                  <h3 className="text-sm font-bold text-left underline">
                    Somos transparentes sobre a forma de utilização dos seus
                    dados
                  </h3>

                  <p className="text-left text-sm my-3.5">
                    Você poderá encontrar nesta página todos os tratamentos
                    relacionados com os seus dados pessoais. Onde, quando, como
                    e porque tratamos os seus dados e também quais são os seus
                    direitos.
                  </p>

                  <h3 className="text-sm font-bold text-left underline">
                    As nossas comunicações são relevantes e adequadas
                  </h3>

                  <p className="text-left text-sm my-3.5">
                    De esportistas para esportistas e de apaixonados para
                    apaixonados, pretendemos apoiá-lo na sua prática e ajudá-lo
                    na escolha dos produtos. Comunicaremos sobre os seus
                    esportes e não sobre outras práticas que não são do seu
                    interesse. Não lhe oferecemos braçadeiras e roupas de
                    natação se acabou de comprar um par de skis, não vamos falar
                    sobre dicas de yoga se começar a praticar judô...
                  </p>

                  <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5">
                    Como usamos seus dados
                  </h3>

                  <h3 className="text-sm font-bold uppercase text-left my-3.5">
                    Compras na loja online
                  </h3>

                  <p className="text-left text-sm my-3.5">
                    Porque coletamos os seus dados?
                  </p>

                  <p className="text-left text-sm">Vamos explicar:</p>

                  <p className="text-left text-sm">
                    Coletamos e tratamos os seus dados pessoais para podermos
                    gerir a sua compra (podemos precisar do seu endereço de
                    entrega ou de faturamento, por exemplo) e também para evitar
                    compras fraudulentas (por exemplo, queremos impedir um
                    pagamento com um cartão furtado). E claro, tratamos ainda
                    dos dados ligados às formas de pagamento!
                  </p>

                  <p className="text-left text-sm my-3.5">
                    Quer saber mais? Segue abaixo caso a caso a forma como
                    tratamos os seus dados numa compra na loja e online.
                  </p>

                  <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5">
                    AS SUAS ENCOMENDAS: SITE OU LOJA
                  </h3>
                </div>

                <section className="section__middle py-0 px-2.5 ">
                  {/* ROW 1 */}
                  <div className="section__middle-row1 xl:flex-row xl:items-start lg:flex-row lg:items-start md:flex-row md:items-start xs:flex-row xs:items-center  sm:items-center flex flex-wrap flex-col items-center">
                    <Order />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5">
                      O NOSSO SISTEMA DE PREVENÇÃO CONTRA OS MEIOS DE PAGAMENTOS
                      FRAUDULENTOS ONLINE
                    </h3>
                    <Fraud />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      O FATURAMENTO DAS SUAS COMPRAS ONLINE OU EM LOJA
                    </h3>
                    <Billing />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      GESTÃO DO PEDIDO COM O CARTÃO PRESENTE
                    </h3>
                    <PresentCard />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      GESTÃO DA CONTA DECATHLON
                    </h3>
                    <AccountManagement />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      GESTÃO DE MARKETING DIRETO
                    </h3>
                    <Marketing />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      POLÍTICA DE COOKIES
                    </h3>
                    <Cookies />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      GESTÃO DAS RETIRADAS DE VENDA
                    </h3>
                    <SalesWithdrawals />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      GESTÃO DOS PEDIDOS EFETUADOS AO ATENDIMENTO AO CLIENTE
                      ESPORTISTA
                    </h3>
                    <SportistClient />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      DECATHLON PLAY
                    </h3>
                    <DecathlonPlay />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      PORTAL #SOUESPORTISTA - EVENTOS ESPORTIVOS ORGANIZADOS POR
                      SUA LOJA
                    </h3>
                    <ImSportist />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      WIFI GRÁTIS LOJAS
                    </h3>
                    <Wifi />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      E-MAIL CARRINHO ABANDONADO
                    </h3>
                    <Cart />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      AVALIAÇÕES PRODUTOS, LOJAS, SITE E EVENTOS DECATHLON
                    </h3>
                    <Reviews />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      BOM SABER
                    </h3>
                    <GoodToKnow />
                    <h3 className="text-blue text-sm font-bold uppercase text-left my-3.5 w-full">
                      OS SEUS DIREITOS
                    </h3>
                    <YourRights />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy
