import { GatsbySeo } from 'gatsby-plugin-next-seo'
import PrivacyPolicy from 'src/components/servicos/ServicesPage/PoliticaPrivacidade'

function Page() {
  return (
    <>
      <GatsbySeo language="pt-br" title="Política de privacidade | Decathlon" />
      <PrivacyPolicy />
    </>
  )
}

export default Page
