/* eslint-disable react/no-unescaped-entities */
const GoodToKnow = () => {
  return (
    <>
      <div className="block1">
        <p className="text-left text-sm">
          Sabe o que é um cookie? Um cookie é um arquivo guardado no seu
          computador ou smartphone que permite armazenar informações relativas à
          utilização dos nossos sites e aplicações. Estes cookies têm várias
          finalidades que vamos explicar...
        </p>

        <h3 className="text-sm font-bold uppercase text-center my-3.5">
          MAIS INFORMAÇÕES SOBRE TRANSFERÊNCIAS
        </h3>

        <p className="text-left text-sm mx-0 my-3.5">
          Sabia que os seus dados podem ser transferidos para fora do Brasil? Na
          DECATHLON, estas transferências são feitas com a maior atenção. Nós
          explicamos....
        </p>

        <h3 className="text-sm font-bold uppercase text-center my-3.5">
          Porque transferimos os seus dados?
        </h3>

        <p className="text-left text-sm mx-0 my-3.5">
          A transferência dos dados para fora do Brasil deve-se principalmente à
          localização da nossa Matriz (na França) e dos nossos
          prestadores/subcontratados.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          De fato, para lhe oferecer um serviço de qualidade, decidimos
          terceirizar determinadas operações para prestadores especializados que
          possuem experiência real nas suas áreas (exemplo: armazenamento). E
          alguns destes prestadores estão estabelecidos fora do território
          nacional.
        </p>

        <h3 className="text-sm font-bold uppercase text-center my-3.5">
          Como enquadramos as transferências de dados?
        </h3>

        <p className="text-left text-sm mx-0 my-3.5">
          Independentemente do país onde os nossos prestadores estão
          localizados, a sua seleção é sempre feita com o máximo rigor. Em
          particular com a segurança dos seus dados. Estes prestadores agem
          apenas mediante instruções da DECATHLON e não usam os seus dados em
          qualquer momento ou para qualquer outra coisa que não os serviços que
          lhes foram confiados.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Quando os nossos prestadores estão estabelecidos fora do Brasil,
          estamos dispostos a selecioná-los em países cujo quadro jurídico
          oferece um nível satisfatório de segurança. O objetivo é transferir os
          seus dados de forma segura e legal.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Caso contrário, supervisionamos essas transferências por meio de
          mecanismos legais apropriados, incluindo a assinatura de cláusulas
          contratuais adicionais especificamente previstas para esse tipo de
          transferência.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Como saber se os seus dados são transferidos?
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          Se deseja saber se os seus dados foram transferidos, consulte a seção
          "Transferências fora do Brasil" na descrição do tratamento em questão.
        </p>
      </div>
    </>
  )
}

export default GoodToKnow
