const Cookies = () => {
  return (
    <>
      <div className="block1">
        <p className="text-left text-sm">
          Sabe o que é um cookie? Um cookie é um arquivo guardado no seu
          computador ou smartphone que permite armazenar informações relativas à
          utilização dos nossos sites e aplicações. Estes cookies têm várias
          finalidades que vamos explicar...
        </p>

        <h3 className="text-sm font-bold uppercase text-left my-3.5">
          Compras na loja online
        </h3>

        <p className="text-left text-sm mx-0 my-3.5">
          <b> Os cookies de funcionamento:</b> permitem utilizar as principais
          funcionalidades do site, como por exemplo a gestão do seu carrinho de
          compras ou manter a sua identificação, enquanto navega no site.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          <b> Os cookies de personalização:</b> permitem personalizar a sua
          visita no site, em função das visitas anteriores, das suas compras,
          etc… Estes cookies permitem encontrar rapidamente o que melhor se
          adequa a você...
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          <b>Os cookies de publicidade:</b> permitem receber as ofertas da
          DECATHLON nos sites dos nossos parceiros externos.
        </p>

        <p className="text-left text-sm mx-0 my-3.5">
          E se não gostar de cookies? Você poderá desabilitar a coleta de
          cookies no seu navegador.
        </p>
      </div>
    </>
  )
}

export default Cookies
