const PresentCard = () => {
  return (
    <>
      <table className=" w-full">
        <tr className="block1 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Responsável pelo tratamento
            </h3>
            <p className="text-center text-sm my-3.5">
              Os dados que você compartilha conosco são tratados pela IGUASPORT
              LTDA - inscrita no CNPJ sob nº 02.314.041/0022-02, com sede na
              Avenida Paulista, nº 854, 5º, 6º e 7° Andares, São Paulo – SP.
            </p>
          </td>

          {/* BLOCK 2 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Dados tratados
            </h3>
            <p className="text-center text-sm my-3.5">
              Tratamos os dados necessários à gestão do cartão presente: o seu
              e-mail, CPF, as informações ligadas ao seu meio de pagamento, os
              comentários e as fotos relativas à personalização do cartão
              presente, o seu nome e sobrenome, telefone, endereço de entrega,
              endereço de faturamento, n.° da encomenda, artigos da encomenda.
            </p>
          </td>
        </tr>
        {/* BLOCK 3 */}
        <tr className="block3 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Prazo de conservação
            </h3>
            <p className="text-center text-sm my-3.5">
              Não guardamos as informações do cartão.
            </p>
          </td>

          {/* BLOCK 4 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Os seus direitos
            </h3>
            <p className="text-center text-sm my-3.5">
              Pode consultar e retificar os seus dados pessoais. Sob
              determinadas condições, você poderá solicitar a transferência dos
              seus dados para uma outra entidade que não a DECATHLON. Basta
              enviar um e-mail para: [dados.pessoais@decathlon.com]
            </p>
          </td>
        </tr>
        {/* BLOCK 5 */}
        <tr className="block5 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Destinatários dos dados
            </h3>
            <p className="text-center text-sm my-3.5">
              No âmbito da gestão de cartão presente, alguns dos nossos serviços
              podem acessar seus dados: a direção administrativa e financeira, a
              informática, o centro de relação cliente e a nosso subcontratante
            </p>
          </td>
          {/* BLOCK 6 */}
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Transferência fora do Brasil
            </h3>
            <p className="text-center text-sm my-3.5">
              Os seus dados pessoais permanecem no Brasil e poderão ser enviados
              para a União Europeia e os Estados Unidos, onde nos empenhamos em
              protegê-los de acordo com o regulamento nacional brasileiro e
              europeu.
            </p>
          </td>
        </tr>

        {/* BLOCK 7 */}
        <tr className="block7 w-full">
          <td className="border-solid border-[1px] border-gray p-[10px]">
            <h3 className="text-sm font-bold text-center text-gray">
              Fundamento legal para o tratamento de dados
            </h3>
            <p className="text-center text-sm my-3.5">
              Este tratamento só é aplicado após obtermos o seu consentimento.
              Os seus dados destinam-se ao nosso serviço Cartão Presente e
              Centro de Relação com o Cliente. Uma empresa subcontratada
              responsável pelo suporte informático da solução também pode ter
              acesso aos seus dados. Esse acesso é rigorosamente supervisionado
              pela DECATHLON.
            </p>
          </td>
        </tr>
      </table>
    </>
  )
}

export default PresentCard
